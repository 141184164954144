import { isCloudRegistrationTask } from "@custom-types/sdb-background-tasks-type-guards";
import { RegisteredData } from "@pages/project-details/project-data-management/registered-data/registered-data-types";
import { createSelector } from "@reduxjs/toolkit";
import { allCaptureTreeRevisionsSelector, openDraftRevisionSelector } from "@store/capture-tree/capture-tree-selectors";
import { sdbBackgroundTasksSelector } from "@store/sdb-background-tasks/sdb-background-tasks-selector";
import { RootState } from "@store/store-helper";
import {
  hasRegisterError,
  hasBadRegistration,
  isRegistered,
  isRegistering,
  isRegisteringOrRegistered,
} from "@pages/project-details/project-data-management/registered-data/registered-data-utils";
import { CaptureApiClient } from "@faro-lotv/service-wires";

/**
 * @returns All RegisteredData revisions currently getting registered or already registered or failed to register.
 *          Sorted from newest creation date to oldest.
 */
export const registeredDataSelector: (state: RootState) => RegisteredData[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      // TF-2019 consider registration revisions from both "RegistrationBackend" and "scene" clients 
      // inorder to show registration views via Inspect button
      const registrations = allCaptureTreeRevisionsSelector(state).filter(
        (registration) => registration.createdByClient === CaptureApiClient.registrationBackend ||
          registration.createdByClient === CaptureApiClient.scene
      );
      const backgroundTasks = sdbBackgroundTasksSelector(state);
      const cloudRegistrationTasks = backgroundTasks.filter(isCloudRegistrationTask);

      const registrationsWithTask: RegisteredData[] = registrations.map((registration) => {
        const task = cloudRegistrationTasks.find(
          (cloudRegistrationTask) =>
            cloudRegistrationTask.context?.elementId === registration.id
        );

        return {
          ...registration,
          task,
        };
      });

      // Filter out canceled revisions.
      return registrationsWithTask
        .filter(isRegisteringOrRegistered)
        .sort((a, b) => {
          const aCreatedAt = new Date(a.createdAt).getTime();
          const bCreatedAt = new Date(b.createdAt).getTime();
          return bCreatedAt - aCreatedAt;
        });
    }
  );

/**
 * @returns true if the newest RegisteredData revision is getting registered.
 */
export const isRegisteringSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const revisions = registeredDataSelector(state);
      return 0 < revisions.length && isRegistering(revisions[0]);
    }
  );

/**
 * @returns true if the newest RegisteredData revision has been registered.
 */
export const isRegisteredSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const revisions = registeredDataSelector(state);
      return 0 < revisions.length && isRegistered(revisions[0]);
    }
  );

/**
 * @returns an object of two booleans depending if the RegisteredData revision has a task that failed to finish
 * or has succeeded but has bad registration.
 */
export const hasRegisterErrorSelector: (state: RootState) => { hasRegisterError: boolean; hasBadRegistration: boolean } =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      // Usual case: We can rely on the newest RegistrationBackend revision, and the related task.
      const revisions = registeredDataSelector(state);
      const hasRevisions = revisions.length > 0;

      // Special case:
      // We need to check for any failed tasks without revision too, since the revision might not have been created at all
      // if the task failed really hard.
      const backgroundTasks = sdbBackgroundTasksSelector(state);
      const cloudRegistrationTasks = backgroundTasks.filter(isCloudRegistrationTask);
      let newestTask = !cloudRegistrationTasks.length ? undefined :
        cloudRegistrationTasks.reduce((newest, task) => {
          const taskCreated = new Date(task.createdAt || 0).getTime();
          const newestCreated = new Date(newest.createdAt || 0).getTime();
          return (taskCreated > newestCreated) ? task : newest;
        }, cloudRegistrationTasks[0]);
      const newestTaskCreated = new Date(newestTask?.createdAt || 0).getTime();

      // Check for cases where the task is probably no longer relevant, so we don't get blocked in error state.
      // The task seems obsolete:
      // - if there is no draft (because it was discarded or published).
      // - if the task was created earlier than the draft.
      const openDraft = openDraftRevisionSelector(state);
      const openDraftCreated = new Date(openDraft?.createdAt || 0).getTime();
      if ((newestTask && !openDraft) ||
        (newestTask && openDraft && newestTaskCreated < openDraftCreated)
      ) {
        newestTask = undefined;
      }

      return {
        hasRegisterError: (hasRevisions && hasRegisterError(revisions[0])) ||
                          (!!newestTask && hasRegisterError(undefined, newestTask)),
        hasBadRegistration: hasRevisions && hasBadRegistration(revisions[0]),
      };
    }
  );
